.mobile-flex{
    display: none;
}
.mobile-block{
    display: none;
}
.mobile-grid{
    display: none;
}
@media only screen and (max-width: 600px) {
    .desktop {
      display: none;
      width: 0;
    }
    .mobile-flex {
        display: flex;
    }
    .mobile-block {
        display: block;
    }
    .mobile-grid {
        display: grid;
    }
}