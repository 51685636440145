.background-btn{
    background-color: #000;
}
.slider-container {
   /* height: 400px;*/
   height: auto;
}
.carousel-item img {
   /* height: 400px;*/
   height: auto;
}
.li-banner a img{
   /* height: 192px;*/
   height: auto;
}
@media (max-width: 768px) {
	.banner-right {
        display: none;
    }
}
@media (max-width: 600px) {
    .slider-container {
        /* height: 280px; */
        height: auto;
    }
	.carousel-item img {
        /* height: 280px; */
        height: auto;
    }
}
.slick-list {
  height: 360px !important;
  margin-top: 35px !important;
  z-index: 10 !important;
}

.slick-arrow slick-prev {
  background: black !important;
}

.slick-prev,
.slick-next {
  top: 33% !important;
  z-index: 1;
  background: #ee4d2d !important;
  font-size: 20px !important;
  font-family: serif;
  font-family: initial;
  width: 30px !important;
  height: 30px !important;
  border-radius: 15px !important;
}

.fix-ml {
  margin-left: 2.5rem!important;
}

.fix-img-div-new {
  height: 250px !important;
  width: auto;
  overflow: hidden;
  text-align: center;
}
.fix-img-div-new a{
  height: 100%;
}
.fix-img-div-new a img{
  height: 100%;
}
.fix-img-new {
  height: 100% !important;
  width: 100% !important;
  -o-object-fit: cover;
     object-fit: cover;
}
.add-actions{
  z-index: 10;
}
.Product{
  margin-top: 60px;
}
.ProductsMobile{
  display: none;
}
.ProductItem {
  margin: auto 10px;
  z-index: 1;
}
.category-name {
  display: block !important;

}
.new-product-content .slick-slider{
  z-index: 1;
}
@media only screen and (max-width: 600px) {
  .ProductsMobile .slick-slider .slick-list {
    height: auto !important;
    margin-top: 15px !important;
    z-index: 10 !important;
  }
  .ProductsMobile{
    display: block;
  }
  .Product{
    margin-top: 25px;
  }
  .slick-arrow{
    display: none !important;
  }
}
.fix-img-div-laptop-area {
  height: 170px;
  width: 230px;
  overflow: hidden;
  text-align: center;
}
.fix-img-div-laptop-area a{
  height: 100%;
  width: 100%;
}
.fix-img-laptop-area {
  height: 100%;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}

.Notice-Discount{
    font-size: 12px;
    background-color: #e51f84;
    color: #fff;
    font-weight: 600;
    border-radius: 100%;
    height: 30px;
    width: 30px;
    line-height: 30px;
    text-align: center;
    position: absolute;
}
.Modal-Mobile{
    height: 75vh;
    border-radius: 20px;
    z-index: 99999;
}
.MM-content{
    display: grid;
    grid-template-columns: 35fr 65fr;
    padding: 15px;
    margin-bottom: 10px;
}
.MM-img{
    position: relative;
}
.MM-img img{
    width: 100%;
    border-radius: 20px 0 0 0;
}
.MM-info{
    margin-left: 15px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
}
.MM-info p{
    margin-bottom: 0;
}
.MM-name{
    font-size: 16px;
}
.MM-discription{
    border-top: #dbdbdb solid 1px;
    text-align: justify;
    padding: 30px 15px 15px;
    overflow-y: scroll;
}

.MuiDrawer-paperAnchorBottom{
    border-radius: 15px 15px 0 0 !important;
} 
.LPIM-PriceContainer{
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
}
.LPIM-Linethrought{
    font-size: 12px;
    text-decoration: line-through;
    color: #666;
}
.LPIM-RPrice, .LPI-price{
    font-size: 14px;
    color: #ea2c2c;
}
.M-quantity{
    margin-top: 10px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
}
.M-add-to-cart{
    width: 120px;
    padding: 5px 0;
    margin-top: 10px;
    background-color: #ee4d2d;
    border: none;
    border-radius: 5px;
    color: #fff;
}
.M-favorite{
    -ms-flex-align: end;
        align-items: flex-end;
    margin-top: 5px !important;
}
.note-product-model{
    width: 96%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    margin: 0 auto 20px;
}
.ProductItemMobile{
    position: relative;
    overflow: hidden;
    width: 98%;
    height: auto;
    margin: 0 1%;
}
.Product-name-mobile {
    display: block;
    font-size: 14px;
    margin-right: 3px;
    margin-bottom: 5px;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
}
.name-category{
    display: block;
    font-size: 12px;
    color: #666 !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
}
.CPIM-PriceContainer{
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
}
.CPIM-RPrice{
    display: block;
    color:#ee4d2d ;
    margin-top: 3px;
    font-size: 14px;
}
.CPIM-Realprice span{
    display: block;
    color: #ee4d2d;
    font-size: 14px;
    margin-top: 22px;
}
.CPIM-linethrought{
    display: block;
    color: #666;
    font-size: 12px;
    text-decoration: line-through;
}
.CPM-btn_buy{
    text-decoration: none;
    color: #fff;
    background-color: #ee4d2d;
    padding: 3px;
    border-radius: 15px;
    text-align: center;
    width: 70px;
    font-size: 10px;
    cursor: pointer;
    margin-top: 5px;
}
.image-product-mobile-fixed {
    height: 130px;
    width: 100%;
    position: relative;
}
.CPM-img {
    height: 100%;
    width: 100%;
    display: block;
}
.CPM-img span {
    width: 100%;
}
.CPM-img img {
    height: 130px;
    width: 100%;
}
.PIM-background{
    background-color: #fff;
    /* border-radius: 8px; */
    -webkit-box-shadow: 0px 3px 11px 0px #e8eafc, 0 3px 3px -2px #b2b2b21a, 0 1px 8px 0 #9a9a9a1a;
            box-shadow: 0px 3px 11px 0px #e8eafc, 0 3px 3px -2px #b2b2b21a, 0 1px 8px 0 #9a9a9a1a;
    border:#e9e6e6  solid 1px;
}
.CPM-body{
    padding: 5px 10px 10px;
}
.container-rating-mobile{
    display: -ms-flexbox;
    display: flex;
    /* display: block; */
    -ms-flex-align: center;
        align-items: center;
    font-size: 10px;
    color: #666;
}
.container-rating-mobile ul li {
    margin-right: 5px !important;
}
.test-rating{
    display: block;
}

/* style watermark price  */
.watermark-price-mobile-container{
	position: absolute;
	left: 0;
	bottom: 0;
}
.background-watermark-mobile {
	height: 24px;
	width: 45px;
	position: relative;
}
.background-watermark-mobile img {
	height: 100%;
	width: 100%;
	z-index: 1;
}
.background-watermark-mobile .watermark-price{
	position: absolute;
	height: 100%;
	width: 70%;
	z-index: 2;
	bottom: 0;
	left: 0;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	    align-items: center;
	-ms-flex-pack: space-evenly;
	    justify-content: space-evenly;
	margin-left: 2px;
	color: #fff;
	font-weight: bold;
    font-size: 13px;
}

@media only screen and (max-width: 480px) {
    .image-product-mobile-fixed {
        height: 100px;
    }
    .CPM-img{
        height: 100px !important;
    }
    .CPM-img img{
        height: 100px !important;
    }
    .Product-name-mobile {
        font-size: 12px;
        display: block !important;
    }
    .name-category{
        font-size: 10px;
    }
    .CPIM-RPrice{
        font-size: 12px;
    }
    .CPIM-Realprice span{
        font-size: 12px;
        margin-top: 17px;
    }
    .CPIM-linethrought{
        font-size: 10px;
    }
    .background-watermark-mobile {
        height: 22px;
        width: 44px;
    }
    .background-watermark-mobile .watermark-price{
        font-size: 12px;
    }
}

.Spinner {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: center;
        justify-content: center;
}
.li-static-banner {
    z-index: -1;
}
.ProductByCategories {
    margin-top: 60px;
}
.Product-categories-mobile{
    display: none;
}
.product-image{
    height: 150px !important;
    width: auto;
}

@media only screen and (max-width: 600px) {
    .ProductByCategories {
        margin-top: 0;
    }
    .Product-category{
        margin: 25px auto 0 !important;
    }
    .category-name{
        font-size: 14px;
        z-index: 1200;
        margin-bottom: 10px;
    }
    .Product-categories-mobile{
        display: block;
        height: 250px;
        /* grid-template-columns: 1fr 1fr 1fr; */
    }
    .title-category{
        width: 100%;
        display: -ms-flexbox;
        display: flex;
        margin-bottom: 0 !important;
        padding-bottom: 0 !important;
    }
    .grow{
        -ms-flex-positive: 1;
            flex-grow: 1;
    }
    .btn_viewmore{
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
            align-items: center;
        -ms-flex-pack: center;
            justify-content: center;
    }
    .btn_viewmore p{
        font-size: 12px;
        padding: 0;
        margin: 0;
    }
}


@media only screen and (max-width: 480px) {
    .Product-categories-mobile{
        display: block;
        height: 210px;
        /* grid-template-columns: 1fr 1fr 1fr; */
    }

}
@media only screen and (max-width: 768px) {
    .li-static-home-content h2 {
        font-size: 30px;
    }
}
@media only screen and (max-width: 575px) {
    .li-static-home-content h2 {
        font-size: 24px;
    }
}
@media only screen and (max-width: 480px) {
    .li-static-home-content h2 {
        font-size: 18px;
    }
}
@media only screen and (max-width: 360px) {
    .li-static-home-content h2 {
        font-size: 16px;
    }
    .li-static-home-content p {
        font-size: 12px;
    }
}
.slick-list {
  height: 360px !important;
  margin-top: 35px !important;
}

.slick-arrow slick-prev {
  background: black !important;
}

.slick-prev,
.slick-next {
  top: 33% !important;
  z-index: 1;
  background: #ee4d2d !important;
  font-size: 20px !important;
  font-family: serif;
  font-family: initial;
  width: 30px !important;
  height: 30px !important;
  border-radius: 15px !important;
}

.fix-ml {
  margin-left: 2.5rem!important;
}

.fix-img-div-trend {
  height: 250px;
  width: auto;
  overflow: hidden;
  text-align: center;
}
.fix-img-trend {
  height: 100%;
  width: 100% !important;
  -o-object-fit: contain;
     object-fit: contain;
}
.Trendding-products{
  margin-top: 60px;
}
@media only screen and (max-width: 600px) {
  .products-mobile .slick-slider .slick-list{
    height: auto !important;
    margin-bottom: 100px;
  }
  .Trendding-products{
    margin-top: 25px;
  }
  .li-trending-product-tab h2{
    font-size: 16px !important;
  }
}
.breadcrumb-area{
    width: 100% !important;
    -webkit-box-sizing: border-box !important;
            box-sizing: border-box !important;
}
.LinkHeader-body ul{
    display: -ms-flexbox;
    display: flex;
}
.LinkHeader-body ul li{
    margin-right: 5px;
}
.ProductAll{
  margin: 60px auto;
}
.Products-search{
  margin: 60px auto !important;
}
.single-product-wrap .fix-img-div {
  height: 250px !important;
  width: 100% !important;
  overflow: hidden;
}
.product-image .fix-img {
  height: 90%;
  width: 100% !important;
  -o-object-fit: contain;
     object-fit: contain;
  padding: 10px;
}
.ProductItem {
  height: 315px;
}

@media only screen and (max-width: 600px) {
  .ProductAll{
    margin: 20px auto 100px;
  }
  .Products-search{
    margin: 20px auto 100px !important;
  }
  .paginatoin-area{
    margin-top: 10px !important;
  }
}
.ProductItemFlex{
    display: -ms-flexbox;
    display: flex;
    position: relative;
    width: 100%;
    height: auto;
    margin-bottom: 10px ;
}
.Product-flex-name {
    display: block;
    font-size: 14px;
    margin-right: 3px;
    margin-bottom: 5px;
}
.name-category{
    display: block;
    font-size: 12px;
    color: #666;
}
.CPIM-PriceContainer{
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
}
.CPIM-RPrice{
    display: block;
    color: #ea2c2c;
    margin-top: 3px;
    font-size: 14px;
}
.CPIM-Realprice span{
    display: block;
    color: #ea2c2c;
    font-size: 14px;
    margin-top: 22px;
}
.CPIM-linethrought{
    display: block;
    color: #666;
    font-size: 12px;
    text-decoration: line-through;
}
.CPM-btn_buy{
    text-decoration: none;
    color: #fff;
    background-color: #ee4d2d;
    padding: 3px;
    border-radius: 15px;
    text-align: center;
    width: 70px;
    font-size: 10px;
    cursor: pointer;
    margin-top: 5px;
}
.product-flex-img{
    height: 130px !important;
    min-width: 100px
}
.product-flex-img img{
    width: 100%;
    min-width: 100px !important;
    height: 130px !important;
}

.PIM-background{
    background-color: #fff;
    /* border-radius: 8px; */
    -webkit-box-shadow: 0px 3px 11px 0px #e8eafc, 0 3px 3px -2px #b2b2b21a, 0 1px 8px 0 #9a9a9a1a;
            box-shadow: 0px 3px 11px 0px #e8eafc, 0 3px 3px -2px #b2b2b21a, 0 1px 8px 0 #9a9a9a1a;
    border:#e9e6e6  solid 1px;
}
.CPM-body{
    -ms-flex-positive: 1;
        flex-grow: 1;
    padding: 5px 10px 10px;
}
@media only screen and (max-width: 480px) {
    .product-flex-img{
        height: 110px !important;
    }
    .product-flex-img img{
        height: 100% !important;
    }
    .Product-name-mobile {
        font-size: 12px;
    }
    .name-category{
        font-size: 10px;
    }
    .CPIM-RPrice{
        font-size: 12px;
    }
    .CPIM-Realprice span{
        font-size: 12px;
        margin-top: 17px;
    }
    .CPIM-linethrought{
        font-size: 10px;
    }
}
.contact-main-page{
    margin: 20px auto 100px;
}
@media only screen and (max-width: 960px) {
    .contact-page-side-content{
        padding-bottom: 0;
    }
    .btn-contact-submit{
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
            align-items: center;
        -ms-flex-pack: center;
            justify-content: center;
    }
    .contact-page-side-content h3{
        font-size: 22px;
        margin-bottom: 10px;
    }
    .contact-page-side-content h4{
        font-size: 18px;
        margin-bottom: 5px;
    }
    .single-contact-block{
        margin-bottom: 20px;
    }

    .contact-form-content h3{
        font-size: 22px;
    }
    .contact-form .form-group {
        margin-bottom: 5px;
    }
    .contact-form .form-group label{
        margin-bottom: 0;
    }
}
.S_image{
    position: relative;
    max-width: 400px;
    /* max-height: 500px; */
}

.zoom{
    position: absolute;
    z-index: 100;
    right: 10px;
    top: 10px;
    cursor: pointer;
    background: transparent;
    border: none;
}

.zoom i {
    opacity: 0.4;
}

.zoom i:hover {
    opacity: 1;
}

.slick-dots {
    margin-left: 0;
}
.slick-thumb {
    bottom: -62px;
} 
.slick-thumb li {
    width: 50px;
    height: auto;
}
.S_image .slick-slider .slick-list {
    height: 450px !important;
}
/* .conatiner-dots .slick-slider .slick-list{
    margin-top: 0 !important;
    height: auto !important;
}
.box-dots{
    height: 50px !important;
}
.box-dots img{
    height: 100%;
} */

/* .image-dots{
    margin: 0 5% !important;
    width: 90%;
    overflow: hidden;
} */
/* @media (max-width: 1700px) {
    .slick-thumb li {
        width: 45px;
    }
}
@media (max-width: 1413px) {
    .S_image{
        margin-bottom: 40px;
    }
    .slick-thumb {
        bottom: -55px;
    }
    .slick-thumb li {
        width: 36px;
    }
}

@media (max-width: 1280px) {
    .S_image{
        max-width: 300px;
        margin-bottom: 30px;
    }
    .slick-thumb {
        bottom: -45px;
    }
    .slick-thumb li {
        width: 30px;
    }
}

@media (max-width: 800px) {
    .S_image{
        max-width: 50vw;
        width: 50vw;
        margin-bottom: 50px;
    }
    .slick-thumb {
        bottom: -55px;
    }
    .slick-thumb li {
        width: 6vw;
    }
}

@media (max-width: 480px) {
    .S_image{
        max-width: 70vw;
        width: 70vw;
        margin-bottom: 50px;
    }
    .slick-thumb {
        bottom: -45px;
    }
    .slick-thumb li {
        width: 8vw;
    }
}

@media (max-width: 360px) {
    .S_image{
        max-width: 90vw;
        width: 90vw;
        margin-bottom: 50px;
    }
    .slick-thumb {
        bottom: -45px;
    }
    .slick-thumb li {
        width: 10vw;
    }
} */

/* @media (max-width: 760px) {
    .S_image{
        max-width: 80vw;
    }
} */

.slick-thumb li img {
    width: 100%;
    height: 100%;
    -webkit-filter: grayscale(100%);
            filter: grayscale(100%);
    border: 1px solid rgba(128, 128, 128, 0.3);
}
.slick-thumb li.slick-active img{
    -webkit-filter: grayscale(0%);
            filter: grayscale(0%);
    border: 1px solid #445268;
}
@media only screen and (max-width: 600px) {
    .S_image .slick-slider .slick-list{
        height: auto !important;
        margin-bottom: 10px;
    }
}
.product-Orther{
  margin:20px auto  30px;
}
.fix-img-div {
  height: 200px !important;
  width: 230px !important;
  overflow: hidden;
}
.fix-img {
  height: 90%;
  width: 90% !important;
  -o-object-fit: contain;
     object-fit: contain;
  padding: 10px;
}
.fix-rating {
  display: inline-block;
  margin-right: 25px;
}

.fix-btn-review {
  height: 33px;
  background: #fcb800;
  border: #6b0bc4cc;
}

.btn-grey {
  background-color: #d8d8d8;
  color: #fff;
}
.rating-block {
  background-color: #fafafa;
  border: 1px solid #efefef;
  padding: 15px 15px 20px 15px;
  border-radius: 3px;
}
.bold {
  font-weight: 700;
}
.padding-bottom-7 {
  padding-bottom: 7px;
}

.review-block {
  background-color: #fafafa;
  border: 1px solid #efefef;
  padding: 25px;
  border-radius: 3px;
  margin-bottom: 15px;
  margin-top: 10px;
}
.review-block-name {
  font-size: 12px;
  margin: 10px 0;
}
.review-block-date {
  font-size: 12px;
}
.review-block-rate {
  font-size: 13px;
  margin-bottom: 15px;
}
.review-block-title {
  font-size: 15px;
  font-weight: 700;
  margin-bottom: 10px;
}
.review-block-description {
  font-size: 13px;
}

.fix-avatar-div {
  height: auto;
  width: 80px;
  overflow: hidden;
}
.fix-avatar {
  height: 90%;
  width: 90% !important;
  -o-object-fit: contain;
     object-fit: contain;
  padding: 5px;
}
.fix-view-all {
  display: block;
  color: #2626e8e0;
  font-size: 15px;
  padding: 2px 0px 10px;
}

.fix-fb {
  z-index: 9999999999;
}

.slick-prev,
.slick-next {
  top: 33% !important;
  z-index: 1;
  background: #ee4d2d  !important;
  font-size: 20px !important;
  font-family: serif;
  font-family: initial;
  width: 30px !important;
  height: 30px !important;
  border-radius: 15px !important;
}

.fix-ml {
  margin-left: 2.5rem!important;
}

.sp-normal-content .product-additional-info {
  padding-top: 15px !important;
}

.fix-width-slick {
  margin: auto;
  text-align: center;
  max-width: 400px !important;
  height: auto;
}

.fix-img-slick {
  height: 100% !important;
  width: 100% !important;
  -o-object-fit: contain;
     object-fit: contain;
  padding: 5px;
  -webkit-transition: -webkit-transform .6s;
  transition: -webkit-transform .6s;
  transition: transform .6s;
  transition: transform .6s, -webkit-transform .6s;
}

.fix-img-div-slick {
  height: 300px !important;
  width: 300px !important;
  overflow: hidden;
}

.fix-img-slick:hover { /* IE 9 */
    -webkit-transform: scale(1.5); /* Safari 3-8 */
    transform: scale(1.5); 
}

.slick-dots li {
  width: auto !important;
  padding: 0 !important;
}

.slick-dots {
  bottom: 30px !important;
}

.ReactModal__Overlay--after-open {
  z-index: 9999;
}
.price-linethrought{
  text-decoration: line-through;
  margin-left: 10px;
}
.slick-list {
  height: 360px !important;
  margin-top: 35px !important;
}

.slick-arrow slick-prev {
  background: black !important;
}

.slick-prev,
.slick-next {
  top: 33% !important;
  z-index: 1;
  background: #ee4d2d !important;
  font-size: 20px !important;
  font-family: serif;
  font-family: initial;
  width: 30px !important;
  height: 30px !important;
  border-radius: 15px !important;
}

.fix-ml {
  margin-left: 2.5rem!important;
}

.fix-img-div-other {
  height: 230px;
  width: auto;
  overflow: hidden;
  text-align: center;
}
.fix-img-div-other a{
  height: 100%;
}
.fix-img-other {
  height: 100%;
  width: 100% !important;
  -o-object-fit: contain;
     object-fit: contain;
}
.product-view-detail{
  margin-top: 40px !important;
}
.image_resized {
  max-width: 100% !important;;
}
.image_resized img{
  width: 100% !important;

}
.totalPrice-text{
  height: auto;
  margin: 10px auto ;
  font-size: 14px;
  font-weight: 600;
  color: #444242;
}
@media only screen and (max-width: 600px) {
  .product-view-detail{
    margin-top: 20px !important;
  }
  /* .product-details-images .fix-width-slick .slick-slider{
    height: 400px;
  } */
  .slick-prev{
    left: 5px !important;
    top: 45% !important;
    z-index: 100;
  }
  .slick-next{
    right: 5px !important;
    top: 45% !important;
    z-index: 100;
  }
  .product-details-images .fix-width-slick .slick-slider .slick-dots{
    bottom: 0 !important;
  }

  /* style rating */
  .rating-block {
    padding: 10px;
  }
  .rating-block h5{
    font-size: 14px;
  }
  .rating-block h2{
    font-size: 18px;
    padding-bottom: 0 !important;
  }
  .container-rating{
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
  } 
  .container-star{
    margin: 5px 10px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
  }
  .review-block{
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    padding: 10px;
  }
  .fix-avatar-div{
    height: 40px;
  }
  .product-Orther{
    margin-bottom: 80px;
  }
  /* Slider orther products */
  .slider-product-orther-mobile .slick-slider .slick-list{
    height: auto !important;
    margin: 20px auto 50px !important;
  }
  .ReactModal__Content {
    width: 90% !important;
    height: 70% !important;
  }
  .title-reating{
    font-size: 18px;
  }
  .modal-body{
    padding: 0;
  }
  .feedback-area{
    width: 90% !important;
  }
  .product-description {
    display: none;
  }
  /* .product-longdesc-title {
    display: none;
  } */
  .slick-list {
    margin-top: 15px !important;
  }
}
.fix-button-resetpw {
  width: 200px !important;
  height: 43px;
  background-color: #ed143ddb;
  margin-top: 10px !important;
}

.fix-button-resetpw {
  border: 1px solid #f1e4e478 !important;
}
.form-login{
  margin: 20px auto 100px !important;
}

.fogetPassword-container{
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  margin: 20px auto 100px;
}

@media only screen and (min-width: 960px) {
  .form-login{
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: center;
        justify-content: center;
  }
  .form-login form {
    width: 50%;
    border-top: 1px solid #f4f4f4;
  }
  #g-recaptcha {
    transform:scale(0.77);
    -webkit-transform:scale(0.77);
    transform-origin:0 0;
    -webkit-transform-origin:0 0;
  }
}
.Shopping-cart-area{
  margin: 20px auto 100px;
}
.fix-cart {
  height: 120px;
  width: 80px;
  overflow: hidden;
}
.fix-img {
  height: 100%;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.fix-text-discount {
  display: block;
  margin-top: 10px;
  font-size: 15px;
  font-family: sans-serif;
  font-weight: 500;
}
.fix-text-checkout {
  width: 100%;
  text-align: center;
  font-family: sans-serif;
  font-weight: bold;
  border-radius: 5px;
}
.Cart-Item-Note{
  padding: 0 !important;
}
.Cart-Item-Note textarea {
  border: none;
}
.box-price{
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
}
.box-price .price-lineThought {
  color: #666;
  font-size: 12px;
  text-decoration: line-through;
}
.container-buyMore{
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  margin-top: 20px;
}
.buyMore{
  width: 50%;
  min-width: 150px;
  background-color: #ee4d2d;
  color: #fff !important;
  display: inline-block;
  padding: 10px 20px;
  text-transform: capitalize;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  border: aliceblue;
  text-align: center;
  font-family: sans-serif;
  font-weight: bold;
  border-radius: 5px;
}
.buyMore:hover{
  background: #e23513;
  border-color: #e23513;
  border: aliceblue;
  color: #fff;
}
.cart-category {
  font-size: 14px;
  color: #666;
}
@media only screen and (max-width: 600px) {
  .Shopping-cart-area{
    margin: 20px auto 100px;
  }
  .sumTotal{
    margin-top: 20px;
  }
  .cart-mobile{
    -ms-flex-direction: column;
        flex-direction: column;
    width: 100%;
  }
  .cart-mobile h3{
    text-align: center;
    font-size: 18px;
    border-bottom: #e6e6e6 solid 1px;
    padding-bottom: 20px;
  }
}

.CartItemMobile{
    width: 90%;
    margin: auto;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    border-bottom: #e6e6e6 solid 1px;
    padding-bottom: 10px;
    margin-top: 10px;
}
.cart-item-mobile-delete{
    margin-right: 10px;
}
.cart-item-mobile-body{
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    width: 100%;
}
.conatiner-name-totalPrice{
    display: -ms-flexbox;
    display: flex;
}
.cart-item-mobile-name{
    color: #000;
    font-size: 14px;
    margin-bottom: 5px;
    margin-right: 20px;
    -ms-flex-positive: 1;
        flex-grow: 1;
}
.cart-item-mobile-categoryName{
    color: #666 !important;
    font-size: 12px;
}
.cart-item-price{
    color: #ea2c2c;
    margin-right: 10px;
    font-size: 14px;
}
.linethrought{
    text-decoration: line-through;
    color: #666;
    font-size: 12px;
}
.totalPrice-moile{
    color: #ea2c2c;
}
.container-note{
    margin-top: 10px;
}
.container-note p:first-child{
    color: #000;
}

.container-note p{
    font-size: 12px;
    margin-bottom: 5px;
}
.container-note textarea {
    font-size: 12px;
}
.container-note p span {
    color: blue;
    text-decoration: underline;
    margin-left: 10px;
}
.conatiner-cart-amount{
    display: -ms-flexbox;
    display: flex;
}
.conatiner-cart-amount p{
    color: blue;
    text-decoration: underline;
    margin-bottom: 0;
}
.cart-amount{
    -ms-flex-positive: 1;
        flex-grow: 1;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
}
.cart-amount input {
    width: 52px;
    height: 28px;
    background-color: #fff;
    text-align: center;
    padding-left: 0;
    outline: none !important;
}
.handleItem{
    height: 28px;
    border: 2px solid #eceff8;
    padding-left: 5px;
    padding-right: 5px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: center;
        justify-content: center;
}
.downItem{
    border-right: none;
}
.upItem{
    border-left: none;
}


.checkout-area{
  margin: 60px auto 100px;
}
.fix-paypal{
    text-align: center;
}
.processOrder{
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  width: 100%;
  margin-bottom: 20px;
}
.processOrder-body{
  width: 50%;
}

.progressbar {
  margin: 0;
  padding: 0;
  counter-reset: step;
}
.progressbar li {
  list-style-type: none;
  width: 25%;
  float: left;
  font-size: 12px;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  color: #7d7d7d;
}
.progressbar li:before {
  width: 30px;
  height: 30px;
  content: counter(step);
  counter-increment: step;
  line-height: 30px;
  border: 2px solid #7d7d7d;
  display: block;
  text-align: center;
  margin: 0 auto 10px auto;
  border-radius: 50%;
  background-color: white;
}
.progressbar li:after {
  width: 100%;
  height: 2px;
  content: '';
  position: absolute;
  background-color: #7d7d7d;
  top: 15px;
  left: -50%;
  z-index: -1;
}
.progressbar li:first-child:after {
  content: none;
}
.progressbar li.active {
  color: green;
}
.progressbar li.active:before {
  border-color: #55b776;
}
.progressbar li.active + li:after {
  background-color: #55b776;
}
.checkbox-form h3{
  text-align: center;
}
.your-order h3{
  text-align: center;
}
.checkout-form-list label{
  margin-left: 5px;
}
.order-note-item{
  color: #666;
  font-size: 12px;
  margin-top: 10px;
}
.container-buyMore{
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  margin-top: 20px;
}
.buyMore{
  width: 50%;
  min-width: 200px;
  background-color: #ee4d2d;
  color: #fff;
  display: inline-block;
  padding: 10px 20px;
  text-transform: capitalize;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  border: aliceblue;
  text-align: center;
  font-family: sans-serif;
  font-weight: bold;
  border-radius: 5px;
  margin-bottom: 20px;
}
.buyMore:hover{
  background: #e23513;
  border-color: #e23513;
  border: aliceblue;
  color: #fff;
}
.container-action-checkout{
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
}

/* Edit */
.order-item-categoryName {
  font-size: 14px !important;
  color: #666 !important;
}
.order-item-categoryName:hover {
  text-decoration: underline;
}
/* end */
@media only screen and (max-width: 760px) {
  .processOrder-body{
    width: 80%;
  }
}
@media only screen and (max-width: 600px) {
  /* Edit */
  .order-item-categoryName {
    font-size: 12px !important;
  }
  /* end */
  .processOrder-body{
    width: 90%;
  }
  .checkout-area{
    margin: 60px auto 100px;
  }
  .checkbox-form h3{
    font-size: 18px;
  }
  .your-order h3{
    font-size: 18px;
  }
  .text-notice h1{
    font-size: 18px;
  }
}
@media only screen and (max-width: 480px) {
  .processOrder-body{
    width: 100%;
  }
  .progressbar li {
    font-size: 10px;
  }
  .checkbox-form h3{
    font-size: 16px;
  }
  .your-order h3{
    font-size: 16px;
  }
}
.ShopCategory{
  margin-top: 60px;
}
.fix-img-div {
    height: 250px !important;
    width: auto !important;
    overflow: hidden;
  }
  .fix-img-div a{
    width: 100%  !important;
  }
  .product-category-conatiner .img-category-product {
    height: 100% !important;
    width: 100% !important;
    -o-object-fit: contain;
       object-fit: contain;
  }

  .fix-img-div2 {
    height: 56px !important;
    width: 140px !important;
    overflow: hidden;
  }
  .fix-img2 {
    height: 95% !important;
    width: 100% !important;
    -o-object-fit: contain;
       object-fit: contain;
    border: 1px solid #e6d7d7;
    border-radius: 5px;
    padding: 1px;
  }
  .price-checkbox li a, .size-checkbox li a, .color-categoriy ul li a, .categori-checkbox ul li a {
    color: #363f4d;
    font-size: 16px;
    margin-left: 0px !important;
    margin-top: 0;
  }

  .fix-producers {
    text-align: left;
    font-size: 19px !important;
    padding-left: 3px !important;
    font-weight: 600 !important;
    font-family: sans-serif;
  }
  .ListPrice li{
    cursor: pointer;
  }
  .ListPrice li:hover p{
    color: #000;
  }
  .filter-product button{
    margin: 10px 10px 0;
}
.product-category-item {
  height: 315px !important;
  z-index: auto;
}
.infinite-scroll-component  {
  overflow: visible !important;
}
.modal-backdrop {
  background-color:rgba(0,0,0,0.05);
  z-index: 1;
}

/* style watermark price  */
.watermark-price-container {
  position: absolute;
  left: 0;
  bottom: 0;
}
.background-watermark {
  height: 27px;
  width: 50px;
  position: relative;
}
.background-watermark img {
  height: 100%;
  width: 100%;
  z-index: 1;
}
.background-watermark .watermark-price{
  position: absolute;
  height: 100%;
  width: 70%;
  z-index: 0;
  bottom: 0;
  left: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: space-evenly;
      justify-content: space-evenly;
  margin-left: 5px;
  color: #fff;
  font-weight: bold;
}

@media only screen and (max-width: 600px) {
    
  .ShopCategory{
    margin-top: 20px;
  }
  .categoryPage{
      margin-bottom: 50px;
    }
    .grid-view-mobile{
      margin-top: 20px;
      grid-template-columns: 1fr 1fr 1fr;
      grid-row-gap: 5px;
    }
    .flex-view-mobile{
       -ms-flex-direction: column;
           flex-direction: column;
       margin: 20px 0 0;
    }
    .paginatoin-area{
      margin-top: 10px !important;
    }
}
.emp-profile {
  width: 90%;
  padding: 20px;
  margin: 50px auto;
  background: #f2f4f740;
  border: 1px solid #ebe3e3;
  border-radius: 8px;
}
.Profile-body{
  display: -ms-flexbox;
  display: flex;
}
.container-img{
  height: 200px;
  width: 200px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-align: center;
      align-items: center;
  margin: 70px 0 0 70px;
}
.profile-img {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  margin-bottom: 10px;
}
.profile-img img {
  width: 100%;
  height: 100%;
}
.profile-img .file {
  position: relative;
  overflow: hidden;
  margin-top: -20%;
  width: 70%;
  border: none;
  border-radius: 0;
  font-size: 15px;
  background: #212529b8;
}
.profile-img .file input {
  position: absolute;
  opacity: 0;
  right: 0;
  top: 0;
}

.container-profile{
  padding: 0 40px 0 70px;
  -ms-flex-positive: 1;
      flex-grow: 1;
}
.container-form-profile{
  width: 100%;
}
.profile-head h5 {
  color: #333;
}
.profile-head h6 {
  color: #0062cc;
}
.profile-edit-btn {
  border: none;
  border-radius: 1.5rem;
  width: 70%;
  padding: 2%;
  font-weight: 600;
  color: #6c757d;
  cursor: pointer;
}
.proile-rating {
  font-size: 12px;
  color: #818182;
  margin-top: 5%;
}
.proile-rating span {
  color: #495057;
  font-size: 15px;
  font-weight: 600;
}
.profile-head .nav-tabs {
  margin-bottom: 5%;
}
.profile-head .nav-tabs .nav-link {
  font-weight: 600;
  border: none;
}
.profile-head .nav-tabs .nav-link.active {
  border: none;
  border-bottom: 2px solid #0062cc;
}
.profile-work {
  padding: 14%;
  margin-top: -15%;
}
.profile-work p {
  font-size: 12px;
  color: #818182;
  font-weight: 600;
  margin-top: 10%;
}
.profile-work a {
  text-decoration: none;
  color: #495057;
  font-weight: 600;
  font-size: 14px;
}
.profile-work ul {
  list-style: none;
}
.profile-tab label {
  font-weight: 600;
}
.profile-tab p {
  font-weight: 600;
  color: #0062cc;
}
.fix-img-div {
  height: 220px;
  width: 230px;
  overflow: hidden;
}
.fix-img {
  height: 90%;
  width: 90% !important;
  -o-object-fit: contain;
     object-fit: contain;
  padding: 10px;
}

.btn-file {
  position: relative;
  overflow: hidden;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  color: rgba(0, 0, 0, 0.65);
  font-size: 12px;
  line-height: 1.5;
  border: 1px solid #212529d6;
  background: #ffff;
  margin-top: 10px;
}
.btn-file input[type="file"] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 100px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  background: white;
  cursor: inherit;
  display: block;
}
.fix-status {
  font-size: 14px;
  color: #f6f7f7;
}
.input-profile{
  display: grid;
  grid-template-columns: 4fr 8fr;
  margin-bottom: 20px;
}

@media only screen and (max-width: 960px) {
  .Profile-body{
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex-align: center;
        align-items: center;
  }
  .input-profile{
    grid-template-columns: 3fr 9fr;
  }
  .container-profile{
    padding: 0 20px;
    -ms-flex-positive: 1;
        flex-grow: 1;
  }
  .container-form-profile{
    margin-top: 10px;
  }
  .container-img{
    margin: 0;
  }
}

@media only screen and (max-width: 600px) {
  .emp-profile{
    margin: 20px auto 0px !important;
    width: 96%;
  }
  .nav-tabs{
    padding-top: 0 !important;
  }
}
@media only screen and (max-width: 480px) {
  .input-profile{
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
  }
}
.booking-area{
  margin: 20px auto 100px;
}
.booking-area .title-history-booking{
  text-align: center;
  margin-bottom: 20px;
}
.price-linethought{
  font-size: 12px;
  text-decoration: line-through;
  padding:0 ;
}
.cart-product-status{
  /* display: flex; */
  justify-items: center;
}
.fix-cart{
  width: 100%;
}
.history-note{
  font-size: 12px;
  margin-bottom: 0;
}
.process-reload {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
}
/* Edit */
.cart-category {
  font-size: 14px;
  color: #666;
}
/* end */
@media only screen and (max-width: 600px) {
  .booking-area .title-history-booking{
    font-size: 18px;
  }
  .history-detail-mobile{
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    width: 90%;
    margin: auto;
  }
  .history-orders-mobile{
    -ms-flex-direction: column;
        flex-direction: column;
    width: 90%;
    margin: auto;
  }
  .history-mobile-item{
    border-bottom: #e6e6e6 solid 1px;
    margin: 10px auto 0;
    padding-bottom: 10px;
  }
  .id-order-history{
    color: blue !important;
    text-decoration: underline;
  }
  .order-status-mobile{
    display: -ms-flexbox;
    display: flex;
  }
  .order-status-mobile span{
    margin-right: 10px;
  }
  .history-item-total{
    display: -ms-flexbox;
    display: flex;
  }
  .history-price-container{
    display: -ms-flexbox;
    display: flex;
  }
  .history-price-container p{
    margin-bottom: 0;
  }
  .price-history-linethrought{
    text-decoration: line-through;
    font-size: 12px;
    margin-left: 5px;
  }
  .price-history{
    color: #ea2c2c;
    margin-left: 5px;
  }
}

.ItemHistory{
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    border-bottom: #e6e6e6 solid 1px;
    margin: 10px 0;
}
.conatiner-name-totalPrice{
    display: -ms-flexbox;
    display: flex;
}
.cart-item-mobile-name{
    color: #000;
    font-size: 14px;
    margin-bottom: 5px;
    margin-right: 20px;
    -ms-flex-positive: 1;
        flex-grow: 1;
}
.container-note{
    margin-top: 10px;
}
.container-note p:first-child{
    color: #000;
}

.container-note p{
    font-size: 12px;
    margin-bottom: 5px;
}

/* Edit */
.cart-item-mobile-categoryName{
    color: #666 !important;
    font-size: 12px;
}
/* end */

.PostDetail {
    max-width: 1200px;
    margin: 60px auto 100px;
}
.post-img {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
        justify-content: center;
    -ms-flex-align: center;
        align-items: center;
    margin-bottom: 40px;
}
.post-img img {
    width: 100%;
    max-width: 600px;
    max-height: 600px;
}
.post-short-description {
    margin-bottom: 40px;
}
.image_resized {
    max-width: 100% !important;;
}
.image_resized img{
    width: 100% !important;

}


.fix-text-item {

  font-size: 13px;

  font-family: "Roboto", sans-serif;

  color: #242424e3;

  padding: 6px;

  font-weight: 100;

}

.fix-link-color {

  color: #242424;

}

.hb-menu{

  background-color: #fff !important;

}

.bg-light {

  font-size: 16px;

  font-weight: 600!important;

  padding: 10px 0 5px;

  font-family: sans-serif;

  /* background-color: gray !important; */

  /* color: black; */

}

.navbar-brand {

  padding-top: 0 !important;

}

.btn-Logout{

  margin-left: 10px;

  cursor: pointer;

}

.btn-Logout:hover {

  color: #ee4d2d;

}

.dropdown-item {

  padding: 10px 15px !important;

}

.container .dropdown .dropdown-menu a:hover

{

  color: #fff;

  background-color: #ee4d2d;

  border-color: #fff;

}

.navbar-light .navbar-nav .nav-link {

  color: #666 !important;

  font-weight: 700;

}

.logo {

  height: 50px;

  width: 100%;

}

.logo a{

  height: 100%;

  width: 100%;

  display: -ms-flexbox;

  display: flex;

  -ms-flex-align: end;

      align-items: flex-end;

  -ms-flex-pack: end;

      justify-content: flex-end;

}

.logo a img{

  margin-right: 0px;

  height: 200%;

  max-width: 300px;

  margin-right: 0px;

  margin-bottom: -30px;

}



.icon-home{

  margin-right: 20px;

}

.hm-wishlist > .order-icon{

  display: -ms-flexbox;

  display: flex;

  -ms-flex-align: center;

      align-items: center;

  -ms-flex-pack: center;

      justify-content: center;

}

.phone-header-top {

  display: -ms-flexbox;

  display: flex;

  -ms-flex-align: center;

      align-items: center;

  -ms-flex-pack: center;

      justify-content: center;

}



/* style list categories */

.List_categories {

  display: -ms-flexbox;

  display: flex;

  -ms-flex-align: center;

      align-items: center;

  padding: 5px 16px;

  -webkit-box-shadow: 0 0 10px 0 rgb(0 0 0 / 15%);

          box-shadow: 0 0 10px 0 rgb(0 0 0 / 15%);

}

.icon_categories {

  font-size: 26px;

  color: #666;

  margin-right: 16px;

}

.category_menu {

  font-weight: 700;

  color: #666;

  text-transform: uppercase;

  margin-left: 8px;

}

.category_menu:hover {

  color: #ee4d2d;

}

.category_menu span {

  margin-left: 8px;

  color: #666 !important;

}

@media (min-width: 1550px) {

  .header-middle .header-container{

    max-width: 1170px !important;

  }

  /* .header-bottom .header-botom-container{

    max-width: 1170px !important;

  } */

}

@media only screen and (max-width: 990px) {

  .logo{

    height: 100px !important;

    margin: 0 !important;

  }

  .logo a{

    -ms-flex-align: center;

        align-items: center;

    -ms-flex-pack: center;

        justify-content: center;

    margin: 0 !important;

  }

  .logo a img{

    margin: 0 !important;

  }

}



@media only screen and (max-width: 600px) {

  .search-box-mobile{

    display: -ms-flexbox !important;

    display: flex !important;

    -ms-flex-align: center;

        align-items: center;

  }

  .hm-searchbox{

    -ms-flex-positive: 1;

        flex-grow: 1;

  }

  .header-middle{

    padding: 15px 0 !important;

  }

}
.SliderCategories{
    display: none;
    border-top: 1px solid #dadada;
}
.rec-item-wrapper{
  height: 45px !important;
}
.SC-all{
  height: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  position: relative;
}
.isFocus{
    font-weight: 700;
    color: #ee4d2d;
    font-size: 12px;
}
.SC-link{
  font-size: 12px;
  color: #666;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
}
.SC-border{
  background-color: #ee4d2d;
  height: 3px;
  width: 140%;
  border-radius: 50px;
  position: absolute;
  bottom: 5px;
}
@media (max-width:600px){
  .SliderCategories{
    display: -ms-flexbox;
    display: flex;
  }
}
.footer-logo{
    height: 60px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex-align: center;
        align-items: center;
    /* margin-bottom: 10px; */
}
.footer-logo img{
    height: 100%;
}
.capcha-footer {
    width: 100%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex-align: end;
        align-items: flex-end;
    -ms-flex-pack: end;
        justify-content: flex-end;
    padding: 100px 0;
}
.capcha-footer h3{
    margin-bottom: 20px;
}
.btn-capcha {
    margin-top: 20px;
    margin-right: 100px;
    /* position: absolute; */
    /* right: 0; */
    background: #fcb800;
    border: none;
    color: #242424;
    height: 45px;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 500;
    padding: 0 28px;
    display: block;
    line-height: 29px;
    /* top: 0; */
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    cursor: pointer;
    z-index: 999;
}
.btn-capcha:hover {
    background: #333333;
    color: #ffffff;
}
.bottomPanel {
  display: none;
  z-index: 1250;
}

@media only screen and (max-width: 600px) {
  .bottomPanel {
    position: fixed;
    left: 0;
    bottom: 0;
    display: block;
    width: 100%;
    background: white;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
        justify-content: space-between;
    -ms-flex-align: center;
        align-items: center;
    border-top: 1px solid #c7c7c7;
    padding: 10px 3% 5px;
  }
  .bottomPanel__items {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex-align: center;
        align-items: center;
    padding: 0 2px;
    font-size: 11px;
    block-size: 40px;
    -webkit-text-fill-color: #ee4d2d;
    color: #dedede;    
  }
  .bottomPanel__icons {
    width: 26px;
    height: 26px;
  }
  .Zalo_icons{
    height: 26px;
    width: 26px;-webkit-box-shadow: 0px 3px 11px 0px #e8eafc, 0 3px 3px -2px #b2b2b21a, 0 1px 8px 0 #9a9a9a1a;box-shadow: 0px 3px 11px 0px #e8eafc, 0 3px 3px -2px #b2b2b21a, 0 1px 8px 0 #9a9a9a1a;
    border:#e9e6e6  solid 1px;
    border-radius: 5px;
  }
  .Zalo_icons img{
    height: 100%;
    width: 100%;
  }
  a,
  a:visited {
    text-decoration: none;
    color: black;
  }
  .focus_icon{
    color:  rgb(239 96 67);
  }
  .zalo-link{
    color: #000;
  }
  .MuiBadge-badge {
    background-color: #ee4d2d !important;
    color: #fff;
    -webkit-text-fill-color:#fff
  }
}

.fix-social {
  position: fixed;
  display: grid;
  z-index: 9999;
  right: 0;
}
.fix-social li {
  margin: 0 auto;
  width: 100%;
  text-align: center;
  margin-bottom: 5px;
}

.fix-div-social {
  height: 100%;
  -ms-flex-align: end;
      align-items: flex-end;
  display: -ms-flexbox;
  display: flex;
  position: fixed;
  z-index: 9999;
  margin-left: 15px;
  bottom: 30%;
}
.fix-div-social ul li{
  height: 35px;
  width: 35px;
  border-radius:20%;
}
.fix-div-social ul li a{
  height: 35px !important;
  width: 35px !important;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
}
.fix-div-social ul li a img{
  height: 100%;
  width: 100%;
}
.zalo-img{
  padding: 3px;
}

.hidden-loading {
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: #ffff;
    position: fixed;
    z-index: 99999999;
    opacity: 0.3;
}
.zalo-chat-widget {
    /*right: 65px !important;*/
    right: 80px !important;
    bottom: 17px !important;
    z-index: 2147483601 !important;
}

.fb_dialog {
    right: 8px !important;
    bottom: 740px!important;
    z-index: 2147483602 !important;
}

#ViberChatBox {
    right: 146px !important;
}
.ks-scroll-top {
    right: 30px !important;
}

#fb-root .fb_dialog {
    position: fixed !important;
    height: 48px !important;
    width: 48px !important;
    right: 5px !important;
    margin: 0 !important;
    bottom: 180px !important;
    top: auto !important;
    background: transparent !important;
    overflow: hidden !important;
    padding: 0px !important;
    border-radius: 50% !important;
}

#fb-root .fb_dialog iframe {
    position: absolute !important;
    top: -6px !important;
    right: -18px !important;
}

#fb-root .fb-customerchat.fb_invisible_flow.fb_iframe_widget iframe {
    bottom: 70px !important;
}
.mobile-flex{
    display: none;
}
.mobile-block{
    display: none;
}
.mobile-grid{
    display: none;
}
@media only screen and (max-width: 600px) {
    .desktop {
      display: none;
      width: 0;
    }
    .mobile-flex {
        display: -ms-flexbox;
        display: flex;
    }
    .mobile-block {
        display: block;
    }
    .mobile-grid {
        display: grid;
    }
}
