.ItemHistory{
    display: flex;
    flex-direction: column;
    border-bottom: #e6e6e6 solid 1px;
    margin: 10px 0;
}
.conatiner-name-totalPrice{
    display: flex;
}
.cart-item-mobile-name{
    color: #000;
    font-size: 14px;
    margin-bottom: 5px;
    margin-right: 20px;
    flex-grow: 1;
}
.container-note{
    margin-top: 10px;
}
.container-note p:first-child{
    color: #000;
}

.container-note p{
    font-size: 12px;
    margin-bottom: 5px;
}

/* Edit */
.cart-item-mobile-categoryName{
    color: #666 !important;
    font-size: 12px;
}
/* end */
