.ProductItemMobile{
    position: relative;
    overflow: hidden;
    width: 98%;
    height: auto;
    margin: 0 1%;
}
.Product-name-mobile {
    display: block;
    font-size: 14px;
    margin-right: 3px;
    margin-bottom: 5px;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
}
.name-category{
    display: block;
    font-size: 12px;
    color: #666 !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
}
.CPIM-PriceContainer{
    display: flex;
    flex-direction: column;
}
.CPIM-RPrice{
    display: block;
    color:#ee4d2d ;
    margin-top: 3px;
    font-size: 14px;
}
.CPIM-Realprice span{
    display: block;
    color: #ee4d2d;
    font-size: 14px;
    margin-top: 22px;
}
.CPIM-linethrought{
    display: block;
    color: #666;
    font-size: 12px;
    text-decoration: line-through;
}
.CPM-btn_buy{
    text-decoration: none;
    color: #fff;
    background-color: #ee4d2d;
    padding: 3px;
    border-radius: 15px;
    text-align: center;
    width: 70px;
    font-size: 10px;
    cursor: pointer;
    margin-top: 5px;
}
.image-product-mobile-fixed {
    height: 130px;
    width: 100%;
    position: relative;
}
.CPM-img {
    height: 100%;
    width: 100%;
    display: block;
}
.CPM-img span {
    width: 100%;
}
.CPM-img img {
    height: 130px;
    width: 100%;
}
.PIM-background{
    background-color: #fff;
    /* border-radius: 8px; */
    box-shadow: 0px 3px 11px 0px #e8eafc, 0 3px 3px -2px #b2b2b21a, 0 1px 8px 0 #9a9a9a1a;
    border:#e9e6e6  solid 1px;
}
.CPM-body{
    padding: 5px 10px 10px;
}
.container-rating-mobile{
    display: flex;
    /* display: block; */
    align-items: center;
    font-size: 10px;
    color: #666;
}
.container-rating-mobile ul li {
    margin-right: 5px !important;
}
.test-rating{
    display: block;
}

/* style watermark price  */
.watermark-price-mobile-container{
	position: absolute;
	left: 0;
	bottom: 0;
}
.background-watermark-mobile {
	height: 24px;
	width: 45px;
	position: relative;
}
.background-watermark-mobile img {
	height: 100%;
	width: 100%;
	z-index: 1;
}
.background-watermark-mobile .watermark-price{
	position: absolute;
	height: 100%;
	width: 70%;
	z-index: 2;
	bottom: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	margin-left: 2px;
	color: #fff;
	font-weight: bold;
    font-size: 13px;
}

@media only screen and (max-width: 480px) {
    .image-product-mobile-fixed {
        height: 100px;
    }
    .CPM-img{
        height: 100px !important;
    }
    .CPM-img img{
        height: 100px !important;
    }
    .Product-name-mobile {
        font-size: 12px;
        display: block !important;
    }
    .name-category{
        font-size: 10px;
    }
    .CPIM-RPrice{
        font-size: 12px;
    }
    .CPIM-Realprice span{
        font-size: 12px;
        margin-top: 17px;
    }
    .CPIM-linethrought{
        font-size: 10px;
    }
    .background-watermark-mobile {
        height: 22px;
        width: 44px;
    }
    .background-watermark-mobile .watermark-price{
        font-size: 12px;
    }
}
