.S_image{
    position: relative;
    max-width: 400px;
    /* max-height: 500px; */
}

.zoom{
    position: absolute;
    z-index: 100;
    right: 10px;
    top: 10px;
    cursor: pointer;
    background: transparent;
    border: none;
}

.zoom i {
    opacity: 0.4;
}

.zoom i:hover {
    opacity: 1;
}

.slick-dots {
    margin-left: 0;
}
.slick-thumb {
    bottom: -62px;
} 
.slick-thumb li {
    width: 50px;
    height: auto;
}
.S_image .slick-slider .slick-list {
    height: 450px !important;
}
/* .conatiner-dots .slick-slider .slick-list{
    margin-top: 0 !important;
    height: auto !important;
}
.box-dots{
    height: 50px !important;
}
.box-dots img{
    height: 100%;
} */

/* .image-dots{
    margin: 0 5% !important;
    width: 90%;
    overflow: hidden;
} */
/* @media (max-width: 1700px) {
    .slick-thumb li {
        width: 45px;
    }
}
@media (max-width: 1413px) {
    .S_image{
        margin-bottom: 40px;
    }
    .slick-thumb {
        bottom: -55px;
    }
    .slick-thumb li {
        width: 36px;
    }
}

@media (max-width: 1280px) {
    .S_image{
        max-width: 300px;
        margin-bottom: 30px;
    }
    .slick-thumb {
        bottom: -45px;
    }
    .slick-thumb li {
        width: 30px;
    }
}

@media (max-width: 800px) {
    .S_image{
        max-width: 50vw;
        width: 50vw;
        margin-bottom: 50px;
    }
    .slick-thumb {
        bottom: -55px;
    }
    .slick-thumb li {
        width: 6vw;
    }
}

@media (max-width: 480px) {
    .S_image{
        max-width: 70vw;
        width: 70vw;
        margin-bottom: 50px;
    }
    .slick-thumb {
        bottom: -45px;
    }
    .slick-thumb li {
        width: 8vw;
    }
}

@media (max-width: 360px) {
    .S_image{
        max-width: 90vw;
        width: 90vw;
        margin-bottom: 50px;
    }
    .slick-thumb {
        bottom: -45px;
    }
    .slick-thumb li {
        width: 10vw;
    }
} */

/* @media (max-width: 760px) {
    .S_image{
        max-width: 80vw;
    }
} */

.slick-thumb li img {
    width: 100%;
    height: 100%;
    filter: grayscale(100%);
    border: 1px solid rgba(128, 128, 128, 0.3);
}
.slick-thumb li.slick-active img{
    filter: grayscale(0%);
    border: 1px solid #445268;
}
@media only screen and (max-width: 600px) {
    .S_image .slick-slider .slick-list{
        height: auto !important;
        margin-bottom: 10px;
    }
}