.Notice-Discount{
    font-size: 12px;
    background-color: #e51f84;
    color: #fff;
    font-weight: 600;
    border-radius: 100%;
    height: 30px;
    width: 30px;
    line-height: 30px;
    text-align: center;
    position: absolute;
}