.PostDetail {
    max-width: 1200px;
    margin: 60px auto 100px;
}
.post-img {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
}
.post-img img {
    width: 100%;
    max-width: 600px;
    max-height: 600px;
}
.post-short-description {
    margin-bottom: 40px;
}
.image_resized {
    max-width: 100% !important;;
}
.image_resized img{
    width: 100% !important;

}

