.zalo-chat-widget {
    /*right: 65px !important;*/
    right: 80px !important;
    bottom: 17px !important;
    z-index: 2147483601 !important;
}

.fb_dialog {
    right: 8px !important;
    bottom: 740px!important;
    z-index: 2147483602 !important;
}

#ViberChatBox {
    right: 146px !important;
}
.ks-scroll-top {
    right: 30px !important;
}

#fb-root .fb_dialog {
    position: fixed !important;
    height: 48px !important;
    width: 48px !important;
    right: 5px !important;
    margin: 0 !important;
    bottom: 180px !important;
    top: auto !important;
    background: transparent !important;
    overflow: hidden !important;
    padding: 0px !important;
    border-radius: 50% !important;
}

#fb-root .fb_dialog iframe {
    position: absolute !important;
    top: -6px !important;
    right: -18px !important;
}

#fb-root .fb-customerchat.fb_invisible_flow.fb_iframe_widget iframe {
    bottom: 70px !important;
}