.bottomPanel {
  display: none;
  z-index: 1250;
}

@media only screen and (max-width: 600px) {
  .bottomPanel {
    position: fixed;
    left: 0;
    bottom: 0;
    display: block;
    width: 100%;
    background: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #c7c7c7;
    padding: 10px 3% 5px;
  }
  .bottomPanel__items {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 2px;
    font-size: 11px;
    block-size: 40px;
    -webkit-text-fill-color: #ee4d2d;
    color: #dedede;    
  }
  .bottomPanel__icons {
    width: 26px;
    height: 26px;
  }
  .Zalo_icons{
    height: 26px;
    width: 26px;box-shadow: 0px 3px 11px 0px #e8eafc, 0 3px 3px -2px #b2b2b21a, 0 1px 8px 0 #9a9a9a1a;
    border:#e9e6e6  solid 1px;
    border-radius: 5px;
  }
  .Zalo_icons img{
    height: 100%;
    width: 100%;
  }
  a,
  a:visited {
    text-decoration: none;
    color: black;
  }
  .focus_icon{
    color:  rgb(239 96 67);
  }
  .zalo-link{
    color: #000;
  }
  .MuiBadge-badge {
    background-color: #ee4d2d !important;
    color: #fff;
    -webkit-text-fill-color:#fff
  }
}
