.Modal-Mobile{
    height: 75vh;
    border-radius: 20px;
    z-index: 99999;
}
.MM-content{
    display: grid;
    grid-template-columns: 35fr 65fr;
    padding: 15px;
    margin-bottom: 10px;
}
.MM-img{
    position: relative;
}
.MM-img img{
    width: 100%;
    border-radius: 20px 0 0 0;
}
.MM-info{
    margin-left: 15px;
    display: flex;
    flex-direction: column;
}
.MM-info p{
    margin-bottom: 0;
}
.MM-name{
    font-size: 16px;
}
.MM-discription{
    border-top: #dbdbdb solid 1px;
    text-align: justify;
    padding: 30px 15px 15px;
    overflow-y: scroll;
}

.MuiDrawer-paperAnchorBottom{
    border-radius: 15px 15px 0 0 !important;
} 
.LPIM-PriceContainer{
    display: flex;
    flex-direction: column;
}
.LPIM-Linethrought{
    font-size: 12px;
    text-decoration: line-through;
    color: #666;
}
.LPIM-RPrice, .LPI-price{
    font-size: 14px;
    color: #ea2c2c;
}
.M-quantity{
    margin-top: 10px;
    display: flex;
    flex-direction: column;
}
.M-add-to-cart{
    width: 120px;
    padding: 5px 0;
    margin-top: 10px;
    background-color: #ee4d2d;
    border: none;
    border-radius: 5px;
    color: #fff;
}
.M-favorite{
    align-items: flex-end;
    margin-top: 5px !important;
}
.note-product-model{
    width: 96%;
    display: flex;
    flex-direction: column;
    margin: 0 auto 20px;
}