.SliderCategories{
    display: none;
    border-top: 1px solid #dadada;
}
.rec-item-wrapper{
  height: 45px !important;
}
.SC-all{
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.isFocus{
    font-weight: 700;
    color: #ee4d2d;
    font-size: 12px;
}
.SC-link{
  font-size: 12px;
  color: #666;
  display: flex;
  align-items: center;
}
.SC-border{
  background-color: #ee4d2d;
  height: 3px;
  width: 140%;
  border-radius: 50px;
  position: absolute;
  bottom: 5px;
}
@media (max-width:600px){
  .SliderCategories{
    display: flex;
  }
}