.ProductItemFlex{
    display: flex;
    position: relative;
    width: 100%;
    height: auto;
    margin-bottom: 10px ;
}
.Product-flex-name {
    display: block;
    font-size: 14px;
    margin-right: 3px;
    margin-bottom: 5px;
}
.name-category{
    display: block;
    font-size: 12px;
    color: #666;
}
.CPIM-PriceContainer{
    display: flex;
    flex-direction: column;
}
.CPIM-RPrice{
    display: block;
    color: #ea2c2c;
    margin-top: 3px;
    font-size: 14px;
}
.CPIM-Realprice span{
    display: block;
    color: #ea2c2c;
    font-size: 14px;
    margin-top: 22px;
}
.CPIM-linethrought{
    display: block;
    color: #666;
    font-size: 12px;
    text-decoration: line-through;
}
.CPM-btn_buy{
    text-decoration: none;
    color: #fff;
    background-color: #ee4d2d;
    padding: 3px;
    border-radius: 15px;
    text-align: center;
    width: 70px;
    font-size: 10px;
    cursor: pointer;
    margin-top: 5px;
}
.product-flex-img{
    height: 130px !important;
    min-width: 100px
}
.product-flex-img img{
    width: 100%;
    min-width: 100px !important;
    height: 130px !important;
}

.PIM-background{
    background-color: #fff;
    /* border-radius: 8px; */
    box-shadow: 0px 3px 11px 0px #e8eafc, 0 3px 3px -2px #b2b2b21a, 0 1px 8px 0 #9a9a9a1a;
    border:#e9e6e6  solid 1px;
}
.CPM-body{
    flex-grow: 1;
    padding: 5px 10px 10px;
}
@media only screen and (max-width: 480px) {
    .product-flex-img{
        height: 110px !important;
    }
    .product-flex-img img{
        height: 100% !important;
    }
    .Product-name-mobile {
        font-size: 12px;
    }
    .name-category{
        font-size: 10px;
    }
    .CPIM-RPrice{
        font-size: 12px;
    }
    .CPIM-Realprice span{
        font-size: 12px;
        margin-top: 17px;
    }
    .CPIM-linethrought{
        font-size: 10px;
    }
}